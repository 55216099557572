import React from 'react';
import styles from './Hero.module.scss';
import { IProps } from './Hero.types';
import ComingSoon from '../../general/ComingSoon/ComingSoon';
import Typography from '../../general/Typography/Typography';
import { CompanyTextSizeEnum } from '../../general/Typography/Typography.enum';

export default function Hero({ content }: IProps): JSX.Element {
  return (
    <div className={styles.container}>
      <Typography.CompanyText text="DIGISPACE" size={CompanyTextSizeEnum.DEFAULT} style={{ marginTop: 35 }} />
      <Typography.CompanyText text={content} size={CompanyTextSizeEnum.LARGE} hasStroke />
      <ComingSoon date="2023-12-31" />
    </div>
  );
}
