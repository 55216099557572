import React from 'react';
import Countdown from 'react-countdown';
import styles from './ComingSoon.module.scss';
import renderer from '../../../helpers/comingSoon.renderer';

export default function ComingSoon({ date }: { date: Date | string | number }) {
  return (
    <Countdown
      date={date}
      intervalDelay={0}
      renderer={
        (dates) => (
          <p className={styles.timer}>
            {renderer(dates)}
          </p>
        )
      }
    />
  );
}
