import React from 'react';
import cn from 'classnames';
import { IProps } from './Typography.types';
import styles from './Typography.module.scss';
import { CompanyTextSizeEnum } from './Typography.enum';

function Typography({ text, size = CompanyTextSizeEnum.DEFAULT }: IProps<CompanyTextSizeEnum>) {
  const className = cn(styles.company, styles[size]);

  return (
    <h1 className={className}>{text}</h1>
  );
}

Typography.CompanyText = function f({
  text, size, hasStroke, style,
}: IProps<CompanyTextSizeEnum>) {
  const className = cn(styles.company, styles[size], { [styles.withStroke]: hasStroke });

  return <h1 className={className} style={style}>{text}</h1>;
};

export default Typography;
